import React from "react";
import image from "../assets";
import { motion } from "framer-motion"

export const About = () => {

  return (
    <div className="about-section" id="aboutUs">
      <motion.div
        initial={{ y: 100, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ duration: 1 }}
        viewport={{ once: true }}
        className="about-button"
      >
        <a href='https://api.whatsapp.com/send?phone=573159273307' target='_blank' className="about-button__link">
          <img
            className="images--one"
            src={image.icon_logo_hover}
            alt="Logo Ariadna Blanco"
          />
          <img
            className="images--two"
            src={image.travel_button}
            alt="Logo Ariadna Blanco"
          />
        </a>
      </motion.div>

      <div className="home-text-section">
        <motion.p
          initial={{ y: 150, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="about-title-text title-primary"
        >
          Sobre <span>nosotros</span>
        </motion.p>
      </div>
      <div className="text-section2">
        <motion.img
          initial={{ y: 150, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          src={image.seccion2}
          alt="Logo"
          className="about-logo"
        />
        <motion.p
          initial={{ y: 100, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="about-text"
        >
          Nos especializamos en la categoría de Travel (Destiny, Hospitality y
          Leisure), colaborando con diversas marcas para alcanzar y consolidar
          sus metas comerciales. Nuestra amplia experiencia con destinos,
          hoteles, resorts, aerolíneas y salas VIP nos ha permitido perfeccionar
          nuestra propia metodología, End2End Performance, respaldada por
          análisis de datos, machine learning e inteligencia artificial, nos
          permite generar estrategias efectivas para optimizar cada acción
          táctica, garantizando así el máximo rendimiento y escalabilidad en los
          resultados.
        </motion.p>
      </div>
    </div>
  );
};
