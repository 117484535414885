import { Tab, Tabs } from "@mui/material";
import React, { useState } from "react";
import image from "../assets";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { useMediaQuery } from 'react-responsive'

import { motion } from "framer-motion"

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

export const SuccessStories = () => {
  const [value, setValue] = useState("one");
  const [valueMobile, setValueMobile] = useState("reto");

  const isDesktop = useMediaQuery({ minWidth: 992 });


  const dataSucces = [
    {
      id: 'one',
      titleTabs: "Palace Resorts",
      title: "<span>Palace</span> Resorts",
      list_slider: [
        {
          imageDesktop: image.palace_desktop,
          imgMobile: image.palace_mobile,
          "reto": "Aprovechar las búsquedas que se estaban generando en el mercado, siendo efectivos en la inversión.",
          "solución": "Creamos una estructura robusta de Google Search teniendo presencia en campañas de Brand, Conquer, Category, Competitor e Interest.",
          "resultados": [
            "Récord histórico en ventas $48M USD, en el primer semestre de 2021.",
            "Ventas directas de Google Search de $10M e indirectamente asistió de alguna manera en el path de conversión en 5,6k ocasiones.",
            "Efectividad de la inversión histórica para las ventas (ROAS: 27).",
            "Disminución del CPC en Google Search del 25%",
          ],
        },
      ],
    },
    {
      id: 'two',
      titleTabs: "The Fives",
      title: "<span>The</span> Fives",
      list_slider: [
        {
          imageDesktop: image.thefives_desktop,
          imgMobile: image.thefives_mobile,
          "context": "Debido a las dinámicas del turismo después del COVID-19, surgió la necesidad de realizar una nueva estrategia digital.",
          "reto": "A partir de una nueva estrategia digital, incrementar las ventas de la marca 1MM y ROAS mínimo del 12.",
          "resultados": [
            "Ventas:",
            "Incremento del 9% de ventas netas en canal directo.",
            "Reducción en la participación de cancelaciones en 10 pp. (3.4M)",
            "Récord histórico en la efectividad de la inversión para las ventas (ROAS: 24).",
          ],
        },
      ],
    },
    {
      id: 'three',
      titleTabs: "Priority Pass",
      title: "<span>Priority</span> Pass",
      list_slider: [
        {
          imageDesktop: image.pass_desktop,
          imgMobile: image.pass_mobile,
          "reto":
            "Aumentar las ventas por medio de una estrategia de paid media, que permita aumentar las ventas en los canales pagos en un 25% en el 2022.",
          "solución":
            "Implementamos el formato de recuperación en el sitio web con el fin de evitar la pérdida de la navegación del usuario, se realizó un proceso de optimización para las campañas de Google, Facebook y Programmatic junto a una afinación de los mercados separándolos y asignando su propio presupuesto, lo que nos permitió poder entender su comportamiento.",
          "resultados": [
            "Logramos un aumento del 154% en las ventas en paid media con un total de 11.523 transacciones y un revenue de 1.675.558 USD, de los cuales, el 40% de las ventas se generaron desde el formato recuperador, 1,4% desde las campañas de social y el 57% desde campañas de Google en los diferentes mercados.",
          ],
        },
      ],
    },
    {
      id: 'four',
      titleTabs: "Blue Diamond",
      title: "<span>Blue</span> Diamond",
      list_slider: [
        {
          imageDesktop: image.diamond_desktop,
          imgMobile: image.diamond_mobile,
          "contexto": "Tras la recuperación del sector turístico tras la pandemia del Covid19 surge la necesidad de recuperar terreno vs los años anteriores.",
          "reto": "A partir de una nueva estrategia digital, incrementar las ventas de la por encima de los 10 MM USD por mes.",
          "resultados": [
            "Incremento del 55% de la meta mensual estimadas.",
            "+71% en Transacciones vs el periodo anterior.",
            "Aumento del 5% en el ticket promedio (3076 usd).",
          ],
        },
      ],
    },
  ]

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeMobile = (event, newValueMobile) => {
    setValueMobile(newValueMobile);
  };

  return (
    <div className="success-section" id="success">
      <motion.h3
        initial={{ y: 100, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.9 }}
        viewport={{ once: true }}
        className="success-section__title title-primary"
      >CASOS DE <strong>ÉXITO</strong></motion.h3>
      <Tabs
        value={value}
        onChange={handleChange}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        {
          dataSucces.map((item) => (
            <Tab key={item.id} label={item.titleTabs} value={item.id} />
          ))
        }
      </Tabs>
      <div className="MuiTabs-body">
        <div
          className="tab-content"
          style={{
            backgroundImage: `url(${image.background_success})`,
          }}
        >
          <Swiper
            spaceBetween={20}
            slidesPerView={1}
            grabCursor={true}
            pagination={{ clickable: false }}
            navigation={true}
            loop={true}
            modules={[Navigation]}
            className="mySwiper"
          >
            {
              dataSucces.map((item, index) => (
                item.id === value && (
                  item.list_slider.map((element, index) => (
                    <SwiperSlide key={index}>
                      <div className="success-data">
                        <motion.h4
                          initial={{ y: -50, opacity: 0 }}
                          whileInView={{ y: 0, opacity: 1 }}
                          transition={{ duration: 0.9 }}
                          viewport={{ once: true }}
                          className="success-data__title title-secondary"
                          dangerouslySetInnerHTML={{ __html: item.title }}
                        ></motion.h4>
                        <motion.img
                          initial={{ opacity: 0 }}
                          whileInView={{ opacity: 1 }}
                          transition={{ duration: 1.5 }}
                          viewport={{ once: true }}
                          className="success-data__image"
                          src={isDesktop ? element.imageDesktop : element.imgMobile}
                          alt={element.title}
                        />

                        {
                          isDesktop && (
                            <div className="success-data__info">
                              {
                                Object.keys(element).map((key, index) => {
                                  if (key !== 'imageDesktop' && key !== 'imgMobile') {
                                    return (
                                      <motion.div
                                        key={index}
                                        initial={{ y: 100, opacity: 0 }}
                                        whileInView={{ y: 0, opacity: 1 }}
                                        transition={{ duration: 0.9 }}
                                        viewport={{ once: true }}
                                        className="success-data__info-item"
                                      >
                                        <h4 className="title title-quaternary">{key.toUpperCase()}</h4>
                                        <div className="description">
                                          {
                                            Array.isArray(element[key]) ?
                                              <ul>
                                                {
                                                  element[key].map((result, index) => (
                                                    <li key={index}>{result}</li>
                                                  ))
                                                }
                                              </ul>
                                              :
                                              <p>{element[key]}</p>
                                          }
                                        </div>
                                      </motion.div>
                                    )
                                  }
                                  return null;
                                })
                              }
                            </div>
                          )
                        }
                        {
                          !isDesktop && (
                            <>
                              <Tabs
                                value={valueMobile}
                                onChange={handleChangeMobile}
                                textColor="secondary"
                                indicatorColor="secondary"
                                aria-label="secondary tabs example"
                                className="MuiTabs-header-mobile"
                              >
                                {
                                  Object.keys(element).map((key, index) => {
                                    if (key !== 'imageDesktop' && key !== 'imgMobile') {
                                      return (
                                        <Tab key={index} label={key} value={key} />
                                      )
                                    }
                                    return null;
                                  })
                                }
                              </Tabs>
                              <div className="MuiTabs-body-mobile">
                                {
                                  Object.keys(element).map((key, index) => {
                                    if (key === valueMobile) {
                                      return (
                                        <div key={index} className="success-data__info">
                                          <div className="description">
                                            {
                                              Array.isArray(element[key]) ?
                                                <ul>
                                                  {
                                                    element[key].map((result, index) => (
                                                      <li key={index}>{result}</li>
                                                    ))
                                                  }
                                                </ul>
                                                :
                                                <p>{element[key]}</p>
                                            }
                                          </div>
                                        </div>
                                      )
                                    }
                                    return null;
                                  })
                                }
                              </div>
                            </>
                          )
                        }


                      </div>
                      <div
                        className="tab-image"
                        style={{ backgroundImage: `url(${image.background_success})` }}
                      ></div>
                    </SwiperSlide>
                  )
                  ))
              ))
            }
          </Swiper>
        </div>

      </div>
    </div>
  );
};