import React from "react";

import { motion } from "framer-motion"

import image from "../assets";
import HubspotContactForm from "./HubspotContactForm";

export const ContactUs = () => {
  return (
    <div className="contact-section" id='contactUs'>
      <div className="contact__image-container">
        <img
          src={image.contact}
          alt="contact"
        />
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="contact__card"
        >
          <motion.h3
            initial={{ y: 200, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className="contact__card-title title-primary"
          >¿ESTÁ LISTO PARA LLEVAR SU NEGOCIO AL <strong>SIGUIENTE NIVEL?</strong></motion.h3>
          <motion.p
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="contact__card-description"
          >En Ariadna Travel estamos emocionados de poder ser su aliado estratégico en la industria de Turismo y Hospitalidad. Nuestra experiencia probada y compromiso con resultados tangibles nos distinguen. ¡Hablemos!</motion.p>
        </motion.div>
      </div>

      <div className="contact__container-form">
        <motion.div
          initial={{ y: 150, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.7 }}
          viewport={{ once: true }}
          className="contact__form"
        >
          <form>
            <HubspotContactForm />
          </form>
        </motion.div>
        <div className="contact__info">
          <motion.h3
            initial={{ y: 150, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="contact__info-title title-secondary"
          >COMENCEMOS A INCREMENTAR SUS <strong>RESULTADOS</strong> DE <strong>NEGOCIOS</strong></motion.h3>
          <motion.p
            initial={{ y: 150, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className="contact__info-description"
          >Póngase en contacto con nosotros hoy mismo para descubrir cómo podemos ayudarlo a aumentar sus ventas y revenue de manera efectiva. Estamos ansiosos por ser su socio en el éxito y esperamos con interés trabajar juntos.</motion.p>
        </div>
        <motion.h4
          initial={{ y: 100, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.7 }}
          viewport={{ once: true }}
          className="contact__subtitle title-tertiary"
        ><strong>¡CONTÁCTENOS AHORA!</strong></motion.h4>
      </div>
    </div>
  );
};
