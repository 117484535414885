import React, { useEffect } from 'react'
import { FormExtension, FormExtension2 } from './extensions'

export const VirtualAsisstant = () => {
  const projectID = process.env.REACT_APP_VIRTUAL_ASSISTANT_ID

  useEffect(() => {
    ;(function (d, t) {
      var originalConsoleInfo = console.info //
      console.info = function () {} //
      var v = d.createElement(t),
        s = d.getElementsByTagName(t)[0]
      v.onload = v.onerror = function () {
        //
        console.info = originalConsoleInfo //
        window.voiceflow.chat.load({
          verify: { projectID: projectID },
          url: 'https://general-runtime.voiceflow.com',
          versionID: 'production',
          autostart: true,
          assistant: {
            extensions: [FormExtension, FormExtension2],
          },
        })
      }
      v.src = 'https://cdn.voiceflow.com/widget/bundle.mjs'
      v.type = 'text/javascript'
      s.parentNode.insertBefore(v, s)
    })(document, 'script')
  }, [])

  return <></>
}

export default VirtualAsisstant
