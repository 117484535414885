import React from "react";
import image from "../assets";

import { motion } from "framer-motion"

export const Clients = () => {
  const clientsData = [
    {
      name: "Scotland",
      logo: image.scotland,
    },
    {
      name: "Palace",
      logo: image.palace,
    },
    {
      name: "Le Blanc",
      logo: image.le_blanc,
    },
    {
      name: "Puerto Rico",
      logo: image.puerto_rico,
    },
    {
      name: "The Fives",
      logo: image.the_fives,
    },
    {
      name: "Miami",
      logo: image.miami,
    },
    {
      name: "Hilton",
      logo: image.hilton,
    },
    {
      name: "Embassy",
      logo: image.embassy,
    },
    {
      name: "Latam",
      logo: image.latam,
    },
    {
      name: "Accor Hotels",
      logo: image.accor_hotels,
    },
    {
      name: "Travel Texas",
      logo: image.travel_texas,
    },
    {
      name: "Blue Diamond",
      logo: image.blue_diamond,
    },
    {
      name: "Visit Orlando",
      logo: image.visit_orlando,
    },
    {
      name: "Melia",
      logo: image.melia,
    },
    {
      name: "Priority",
      logo: image.priority,
    },
  ];

  return (
    <div className="client-section" id="clients">
      <motion.h3
        initial={{ y: 100, opacity: 0 }}
        whileInView={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.9 }}
        viewport={{ once: true }}
        className="client-section__title title-primary"
      >ALGUNOS DE NUESTROS <strong>CLIENTES</strong></motion.h3>

      <div className="client-section__logos">
        {clientsData.map((client, index) => (
          <div
            key={index}
            className="client-section__item"
          >
            <motion.img
              initial={{ y: 100, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.6 }}
              viewport={{ once: true }}
              src={client.logo}
              alt={client.name}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
