import React from 'react'
import { motion } from "framer-motion"

import image from "../assets"

export const Footer = () => {
  const linkPrivacy = 'https://ariadnacommunicationsgroup.com/sites/default/files/2024-02/POLITICA-TRATAMIENTO-DE-DATOS-ARIADNA.pdf'

  return (
    <motion.footer
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 1 }}
      viewport={{ once: true }}
      className='footer-section'
    >
      <div className='footer__logo'>
        <img src={image.travel_menu} alt='logo' />
      </div>

      <div className='footer__information'>
        <h3 className='footer__information-title'>Información de contácto</h3>
        <div className='footer__information-contact'>
          <div className='contact-item'>
            <img className='contact-item__icon' src={image.icon_telephone} alt='contact' />
            <a className='contact-item__link' href='https://api.whatsapp.com/send?phone=573159273307' target='_blank'>(+57) 315 9273307 - WhatsApp</a>
          </div>
          <div className='contact-item'>
            <img className='contact-item__icon' src={image.icon_address} alt='contact' />
            <p className='contact-item__address'>Calle 99 # 49 - 53- Bogota, COL</p>
          </div>
          <div className='contact-item'>
            <img className='contact-item__icon' src={image.icon_email} alt='contact' />
            <a className='contact-item__link' href='mailto:yudy.montoya@ariadnacg.com'>yudy.montoya@ariadnacg.com</a>
          </div>
        </div>

        <div className='footer__information-menu'>
          <a href='#aboutUs'>Sobre nosotros</a>
          <a href='#end2End'>Modelo end2end</a>
          <a href='#services'>Nuestros servicios</a>
          <a href='#typeBusiness'>Tipo de empresas</a>
          <a href='#success'>Casos de éxito</a>
          <a href='#clients'>Clientes</a>
          <a href='#contactUs'>Contáctenos</a>
        </div>

        <div className='footer__information-social-media'>
          <a href='https://www.linkedin.com/company/ariadnatravel/' target='_blank'>
            <img src={image.icon_linkedin} alt='linkedin' />
          </a>
        </div>

        <div className='footer__information-privacy'>
          <p>© 2023 / Todos los derechos Reservados / Ariadna Communications Group / <a href='/'>Travel</a> / <a href={linkPrivacy}>Política de privacidad</a></p>
        </div>
      </div>

    </motion.footer>
  )
}
