import "./App.scss";
import { About } from "./components/About";
import { Banner } from "./components/Banner";
import { Clients } from "./components/Clients";
import { CompanyTypes } from "./components/CompanyTypes";
import { ContactUs } from "./components/ContactUs";
import { EndToEndModel } from "./components/EndToEndModel";
import { Footer } from "./components/Footer";
import { Services } from "./components/Services";
import { SuccessStories } from "./components/SuccessStories";
import VirtualAsisstant from "./components/VirtualAsisstant";

function App() {
  return (
    <div className="App">
      <Banner />
      <About />
      <EndToEndModel />
      <Services />
      <CompanyTypes />
      <SuccessStories />
      <Clients />
      <ContactUs />
      <Footer />
      <VirtualAsisstant />
    </div>
  );
}

export default App;
