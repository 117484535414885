export const FormExtension = {
  name: 'Forms',
  type: 'response',
  match: ({ trace }) =>
    trace.type === 'ext_form' || trace.payload.name === 'ext_form',
  render: ({ trace, element }) => {
    const formContainer = document.createElement('form')

    formContainer.innerHTML = `<style>
        label {
          font-size: 0.8em;
          color: #888;
        }
        input[type="text"], input[type="email"], input[type="tel"] {
          width: 100%;
          border: none;
          border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
          background: transparent;
          margin: 5px 0;
          outline: none;
        }
        .phone {
          width: 150px;
        }
        .invalid {
          border-color: red;
        }
        .submit {
          background: linear-gradient(to right, #454773, #454773);
          border: none;
          color: white;
          padding: 10px;
          border-radius: 5px;
          width: 100%;
          cursor: pointer;
          opacity: 0.3;
        }
        .submit:enabled {
          opacity: 1; /* Make the button fully opaque when it's enabled */
        }
      </style>

      <label for="name">Escribir Nombre Completo</label>
      <input type="text" class="name" name="name" required><br><br>

      <label for="email">Escribir Correo Electrónico</label>
      <input type="text" class="email" name="email" required><br><br>
      
      <label for="phone">Escribir Número de Teléfono (Opcional)</label>
      <input type="tel" class="phone" name="phone" pattern="\\d+" title="Número de teléfono no válido, ingrese solo números"><br><br>

      <input type="submit" class="submit" value="Enviar" disabled="disabled">
    `

    const submitButton = formContainer.querySelector('.submit')
    const inputs = formContainer.querySelectorAll('input')

    const validateEmail = (email) => {
      const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i
      return emailPattern.test(email)
    }

    const validateForm = () => {
      let allValid = true

      inputs.forEach((input) => {
        if (input.type === 'text' && input.classList.contains('email')) {
          if (!validateEmail(input.value)) {
            input.classList.add('invalid')
            allValid = false
          } else {
            input.classList.remove('invalid')
          }
        } else if (!input.checkValidity()) {
          input.classList.add('invalid')
          allValid = false
        } else {
          input.classList.remove('invalid')
        }
      })

      submitButton.disabled = !allValid
    }

    inputs.forEach((input) => {
      input.addEventListener('input', validateForm)
      input.addEventListener('blur', validateForm) // Validar también al perder foco
    })

    formContainer.addEventListener('submit', function (event) {
      event.preventDefault()

      const name = formContainer.querySelector('.name')
      const email = formContainer.querySelector('.email')
      const phone = formContainer.querySelector('.phone')

      if (!name.checkValidity() || !validateEmail(email.value)) {
        name.classList.add('invalid')
        email.classList.add('invalid')
        return
      }

      formContainer.querySelector('.submit').remove()

      window.voiceflow.chat.interact({
        type: 'complete',
        payload: {
          name: name.value,
          email: email.value,
          phone: phone.value || '',
        },
      })
    })

    element.appendChild(formContainer)
  },
}

export const FormExtension2 = {
  name: 'Forms2',
  type: 'response',
  match: ({ trace }) =>
    trace.type === 'ext_form2' || trace.payload.name === 'ext_form2',
  render: ({ trace, element }) => {
    const formContainer = document.createElement('form')

    formContainer.innerHTML = `<style>
        label {
          font-size: 0.8em;
          color: #888;
        }
        input[type="text"], input[type="email"], input[type="tel"], textarea {
          width: 100%;
          border: none;
          border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
          background: transparent;
          margin: 5px 0;
          outline: none;
        }
        .phone {
          width: 150px;
        }
        .invalid {
          border-color: red;
        }
        .submit {
          background: linear-gradient(to right, #454773, #454773);
          border: none;
          color: white;
          padding: 10px;
          border-radius: 5px;
          width: 100%;
          cursor: pointer;
          opacity: 0.3;
        }
        .submit:enabled {
          opacity: 1; /* Make the button fully opaque when it's enabled */
        }
      </style>

      <label for="name">Escribir Nombre Completo</label>
      <input type="text" class="name" name="name" required><br><br>

      <label for="email">Escribir Correo Electrónico</label>
      <input type="text" class="email" name="email" required><br><br>
      
      <label for="phone">Escribir Número de Teléfono (Opcional)</label>
      <input type="tel" class="phone" name="phone" pattern="\\d+" title="Número de teléfono no válido, ingrese solo números"><br><br>

      <label for="message">Escribir Mensaje</label>
      <textarea class="message" name="message" required></textarea><br><br>

      <input type="submit" class="submit" value="Enviar" disabled="disabled">
    `

    const submitButton = formContainer.querySelector('.submit')
    const inputs = formContainer.querySelectorAll('input, textarea')

    const validateEmail = (email) => {
      const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i
      return emailPattern.test(email)
    }

    const validateForm = () => {
      let allValid = true

      inputs.forEach((input) => {
        if (input.type === 'text' && input.classList.contains('email')) {
          if (!validateEmail(input.value)) {
            input.classList.add('invalid')
            allValid = false
          } else {
            input.classList.remove('invalid')
          }
        } else if (!input.checkValidity()) {
          input.classList.add('invalid')
          allValid = false
        } else {
          input.classList.remove('invalid')
        }
      })

      submitButton.disabled = !allValid
    }

    inputs.forEach((input) => {
      input.addEventListener('input', validateForm)
      input.addEventListener('blur', validateForm) // Validar también al perder foco
    })

    formContainer.addEventListener('submit', function (event) {
      event.preventDefault()

      const name = formContainer.querySelector('.name')
      const email = formContainer.querySelector('.email')
      const phone = formContainer.querySelector('.phone')
      const message = formContainer.querySelector('.message')

      if (
        !name.checkValidity() ||
        !validateEmail(email.value) ||
        !message.checkValidity()
      ) {
        name.classList.add('invalid')
        email.classList.add('invalid')
        message.classList.add('invalid')
        return
      }

      formContainer.querySelector('.submit').remove()

      window.voiceflow.chat.interact({
        type: 'complete',
        payload: {
          name: name.value,
          email: email.value,
          phone: phone.value || '',
          message: message.value,
        },
      })
    })

    element.appendChild(formContainer)
  },
}
