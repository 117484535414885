import React, { useEffect, useState } from 'react'

import image from "../assets/index.js";
import { Navbar } from './Navbar.js';
import { motion } from "framer-motion"
import { useMediaQuery } from 'react-responsive'

export const Banner = () => {

  const [showContent, setShowContent] = useState(false)

  const isMobile = useMediaQuery({ maxWidth: 768 });

  const showLogo = {
    opacity: 1,
    y: -100,
    display: "block",
    transition: {
      delay: 0.3,
      duration: 0.5
    }
  }

  const hidenLogo = {
    opacity: 0,
    y: 0,
    transition: {
      duration: 0.3
    },
    transitionEnd: {
      display: 'none'
    }
  }

  const showInfo = {
    opacity: 1,
    y: 0,
    display: "block",
    transition: {
      delay: 0.5,
      duration: 0.5
    }
  }

  const hidenInfo = {
    opacity: 0,
    y: 100,
    transition: {
      duration: 0.2
    },
    transitionEnd: {
      display: 'none'
    }
  }

  const handleScroll = () => {
    window.scrollY > 0 ? setShowContent(true) : setShowContent(false)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="banner__container">
      {
        showContent && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.6 }}
            className="banner__button"
          >
            <Navbar />
          </motion.div>
        )

      }
      <div className="container__img">
        <img
          className="banner__image"
          src={isMobile ? image.background_mobile : image.background}
          alt="Imagen de banner"
        />
        <div className="banner__overlay"></div>
      </div>
      <div className="banner__text">
        {

          <motion.img
            animate={!showContent ? showLogo : hidenLogo}
            className="banner__logo"
            src={image.travel}
            alt="Logo"
          />
        }
        {
          <>
            <motion.div
              animate={showContent ? showInfo : hidenInfo}
              className="banner__quotes"
            >
              <h1 className="title title-primary">Su <strong>Aliado</strong> en la Optimización y Crecimiento de su <strong>Canal Directo</strong></h1>
              <p className="text">
                <img className="quotation-marks--left" src={image.quotes_left} alt="Comillas" /><span>
                  Impulsamos la responsabilidad y la eficiencia de su negocio a través del canal directo. <strong>Somos su socio estratégico en el crecimiento de resultados de ventas.</strong> Descubra cómo llevar su negocio al siguiente nivel con nosotros.</span>
                <img className="quotation-marks--right" src={image.quotes_right} alt="Comillas" />
              </p>

            </motion.div>
            <motion.div
              animate={showContent ? showInfo : hidenInfo}
              className="banner__button"
            >
              <a href="#end2End" className="link">Conoce Nuestro Modelo</a>
            </motion.div>

          </>
        }
      </div>
    </div>
  )
}
