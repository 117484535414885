import React, { useRef } from "react";
import image from "../assets";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Mousewheel } from 'swiper/modules';
import { motion } from "framer-motion"
import { useScrollHorizontalWheel } from "../hooks/useScrollHorizontalWheel";

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/mousewheel';

export const EndToEndModel = () => {
  const cardsData = [
    {
      title: "FASE 01:",
      subtitle: "INMERSIÓN Y ANÁLISIS (ONBOARDING INTEGRAL):",
      image: "/static/images/cards/contemplative-reptile.jpg",
      description:
        "Nos sumergimos en la marca para comprender su esencia y evaluar su desempeño actual. Este análisis proporciona una visión clara para identificar áreas de mejora; desde el producto hasta las acciones tácticas para comunicar.",
    },
    {
      title: "FASE 02:",
      subtitle: "MAPEO DEL RECORRIDO DEL CLIENTE (FLUJO DE COMPRA):",
      image: "/static/images/cards/contemplative-reptile.jpg",
      description:
        "Clarificamos el proceso del usuario desde el descubrimiento hasta la conversión. Comprender este recorrido para optimizar la experiencia del usuario y aumentar la efectividad tras cada conversión.",
    },
    {
      title: "FASE 03:",
      subtitle: "OPTIMIZACIÓN DEL SITIO (REFINAMIENTO PARA CONVERSIÓN):",
      image: "/static/images/cards/contemplative-reptile.jpg",
      description:
        "Analizamos y mejoramos el sitio web y su contenido para aumentar su posicionamiento, mejorar la experiencia del usuario y la tasa de conversión, facilitando los flujos de decisión que tiene que hacer el usuario hasta la compra.",
    },
    {
      title: "FASE 04:",
      subtitle: "ESTRATEGIA DE DATOS Y ETIQUETADO (TAGGING ESTRATÉGICO):",
      image: "/static/images/cards/contemplative-reptile.jpg",
      description:
        "Desarrollamos una estrategia de etiquetado precisa para recopilar datos y comprender el comportamiento del usuario, fundamentales para decisiones informadas.",
    },
    {
      title: "FASE 05:",
      subtitle: "COMUNICACIÓN DE MARCA (POSICIONAMIENTO ESTRATÉGICO):",
      image: "/static/images/cards/contemplative-reptile.jpg",
      description:
        "Desarrollamos una estrategía de comunicación que refleje la identidad y valores de la marca, diferenciándola en el mercado y construyendo relaciones sólidas a partir del entendimiento del Buyer persona, para ser más eficientes con todo lo que comunica la marca.",
    },
    {
      title: "FASE 06:",
      subtitle: "CANALES Y ATRIBUCIÓN (ESTRATEGÍA DE PERFORMANCE OMNICANAL):",
      image: "/static/images/cards/contemplative-reptile.jpg",
      description:
        "Identificamos canales efectivos y realizamos acciones tácticas, optimizando el presupuesto para comprender y maximizar el impacto de cada canal en los resultados y su rol dentro de la atribución.",
    },
    {
      title: "FASE 07:",
      subtitle: "MEJORA CONTINUA Y ESCALAMIENTO (REFINAMIENTO SOSTENIBLE):",
      image: "/static/images/cards/contemplative-reptile.jpg",
      description:
        "Implementar un proceso de mejora continua basado en el aprendizaje y la optimización para un crecimiento sostenido y escalable de los resultados, estableciendo metas de negocio ambiciosas según datos y resultados.",
    },
  ];

  const endToEndSliderRef = useRef(null);
  useScrollHorizontalWheel(endToEndSliderRef);

  return (
    <div className="end2-end-section" id="end2End">
      <div className="content-info">
        <motion.div
          initial={{ x: 80, y: 100, opacity: 0 }}
          whileInView={{ x: 100, y: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className="end2-section-title"
        >
          <p className="title-primary"
          >NUESTRO MODELO <strong>END2END <span className="performance">PERFOR<span>MANCE</span></span></strong></p>
        </motion.div>
        <motion.img
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          src={image.seccion3}
          alt="End2End"
          className="end2-image"
        />

        <div className="carousel-container" ref={endToEndSliderRef}>
          <Swiper
            spaceBetween={50}
            slidesPerView={2.5}
            grabCursor={true}
            pagination={{ clickable: false }}
            modules={[Mousewheel]}
            mousewheel={true}
            className="mySwiper"
            breakpoints={
              {
                0: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 1.5,
                  spaceBetween: 50,
                },
                1200: {
                  slidesPerView: 2.5,
                },
              }
            }
          >
            {cardsData.map((card, index) => (
              <SwiperSlide key={index} className="test">
                <div className="card">
                  <div className="card-content">
                    <h2 className="card-title title-primary">{card.title}</h2>
                    <p className="card-subtitle">{card.subtitle}</p>
                    <p className="card-description">{card.description}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};
