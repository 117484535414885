import travel from "./svg/travel.svg";
import travel_menu from "./svg/travel_menu.svg";
import travel_button from "./svg/travel_button.svg";
import card1 from "./svg/card1.svg";
import card2 from "./svg/card2.svg";
import card3 from "./svg/card3.svg";
import card4 from "./svg/card4.svg";
import card5 from "./svg/card5.svg";
import card6 from "./svg/card6.svg";
import card7 from "./svg/card7.svg";
import card8 from "./svg/card8.svg";
import card9 from "./svg/card9.svg";
import quotes_left from "./svg/quotes_left.svg";
import quotes_right from "./svg/quotes_right.svg";
import icon_address from "./svg/icon_address.svg";
import icon_email from "./svg/icon_email.svg";
import icon_linkedin from "./svg/icon_linkedin.svg";
import icon_telephone from "./svg/icon_telephone.svg";
import icon_logo_ariadna_white from "./svg/logo_a_white.svg";
import icon_logo_hover from "./svg/logo_hover.svg";

import seccion2 from "./png/seccion2.png";
import seccion3 from "./png/seccion3.png";
import seccion4 from "./png/seccion4.png";
import background from "./png/background.png";
import background_mobile from "./png/background_mobile.png";
import background_success from "./png/background-success.png";
import seccion5_img1 from "./png/seccion5_img1.png";
import seccion5_img2 from "./png/seccion5_img2.png";
import seccion5_img3 from "./png/seccion5_img3.png";
import seccion6_img1 from "./png/seccion6_img1.png";
import benefits_agencia from "./png/agencia_de_viajes.png";
import benefits_renta from "./png/renta_de_carro.png";
import benefits_hotels from "./png/hoteles_y_resorts.png";

import accor_hotels from "./png/clients/accor_hotels.png";
import blue_diamond from "./png/clients/blue_diamond.png";
import embassy from "./png/clients/embassy.png";
import hilton from "./png/clients/hilton.png";
import latam from "./png/clients/latam.png";
import le_blanc from "./png/clients/le_blanc.png";
import melia from "./png/clients/melia.png";
import miami from "./png/clients/miami.png";
import palace from "./png/clients/palace.png";
import priority from "./png/clients/priority.png";
import puerto_rico from "./png/clients/puerto_rico.png";
import scotland from "./png/clients/scotland.png";
import the_fives from "./png/clients/the_fives.png";
import travel_texas from "./png/clients/travel_texas.png";
import visit_orlando from "./png/clients/visit_orlando.png";
import contact from "./png/contact.png";

// Case of success
import palace_desktop from "./png/success-palace-desktop.png";
import thefives_desktop from "./png/success-fives-desktop.png";
import pass_desktop from "./png/success-pass-desktop.png";
import diamond_desktop from "./png/success-diamod-desktop.png";
import palace_mobile from "./png/success-palace-mobile.png";
import thefives_mobile from "./png/success-fives-mobile.png";
import pass_mobile from "./png/success-pass-mobile.png";
import diamond_mobile from "./png/success-diamond-mobile.png";

const image = {
  background,
  background_mobile,
  background_success,
  travel,
  travel_menu,
  travel_button,
  seccion2,
  seccion3,
  seccion4,
  card1,
  card2,
  card3,
  card4,
  card5,
  card6,
  card7,
  card8,
  card9,
  quotes_left,
  quotes_right,
  icon_address,
  icon_email,
  icon_linkedin,
  icon_telephone,
  icon_logo_ariadna_white,
  icon_logo_hover,
  seccion5_img1,
  seccion5_img2,
  seccion5_img3,
  seccion6_img1,
  benefits_agencia,
  benefits_renta,
  benefits_hotels,
  accor_hotels,
  blue_diamond,
  embassy,
  hilton,
  latam,
  le_blanc,
  melia,
  miami,
  palace,
  priority,
  puerto_rico,
  scotland,
  the_fives,
  travel_texas,
  visit_orlando,
  contact,
  palace_desktop,
  thefives_desktop,
  pass_desktop,
  diamond_desktop,
  palace_mobile,
  thefives_mobile,
  pass_mobile,
  diamond_mobile
};

export default image;
