import React, { useRef } from "react";
import image from "../assets";
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from "framer-motion"
import { useScrollHorizontalWheel } from "../hooks/useScrollHorizontalWheel";

// Import Swiper styles
import 'swiper/css';

export const Services = () => {
  const cardsData = [
    {
      title: "TAGGING & ANALYTICS",
      image: image.card1,
      description:
        "Nos sumergimos en la marca para comprender su esencia y evaluar su desempeño actual. Este análisis proporciona una visión clara para identificar áreas de mejora; desde el producto hasta las acciones tácticas para comunicar.",
    },
    {
      title: "ESTRATEGIA Y PERFORMANCE MARKETING",
      image: image.card2,
      description:
        "Clarificamos el proceso del usuario desde el descubrimiento hasta la conversión. Comprender este recorrido para optimizar la experiencia del usuario y aumentar la efectividad tras cada conversión.",
    },
    {
      title: "CREATIVIDAD Y CONTENIDOS",
      image: image.card3,
      description:
        "Analizamos y mejoramos el sitio web y su contenido para aumentar su posicionamiento, mejorar la experiencia del usuario y la tasa de conversión, facilitando los flujos de decisión que tiene que hacer el usuario hasta la compra.",
    },
    {
      title: "INBOUND & AUTOMATION MARKETING",
      image: image.card4,
      description:
        "Desarrollamos una estrategia de etiquetado precisa para recopilar datos y comprender el comportamiento del usuario, fundamentales para decisiones informadas.",
    },
    {
      title: "POSICIONAMIENTO ORGÁNICO",
      image: image.card5,
      description:
        "Desarrollamos una estrategía de comunicación que refleje la identidad y valores de la marca, diferenciándola en el mercado y construyendo relaciones sólidas a partir del entendimiento del Buyer persona, para ser más eficientes con todo lo que comunica la marca.",
    },
    {
      title: "DIGITAL MEDIA",
      image: image.card6,
      description:
        "Identificamos canales efectivos y realizamos acciones tácticas, optimizando el presupuesto para comprender y maximizar el impacto de cada canal en los resultados y su rol dentro de la atribución.",
    },
    {
      title: "DESARROLLO Y SOLUCIONES TECNOLÓGICAS",
      image: image.card7,
      description:
        "Implementar un proceso de mejora continua basado en el aprendizaje y la optimización para un crecimiento sostenido y escalable de los resultados, estableciendo metas de negocio ambiciosas según datos y resultados.",
    },
    {
      title: "CRO (OPTIMIZACIÓN DE TASA DE CONVERSIÓN)",
      image: image.card8,
      description:
        "Implementar un proceso de mejora continua basado en el aprendizaje y la optimización para un crecimiento sostenido y escalable de los resultados, estableciendo metas de negocio ambiciosas según datos y resultados.",
    },
    {
      title: "CONSULTORÍA EN TRANSFORMACIÓN DIGITAL",
      image: image.card9,
      description:
        "Implementar un proceso de mejora continua basado en el aprendizaje y la optimización para un crecimiento sostenido y escalable de los resultados, estableciendo metas de negocio ambiciosas según datos y resultados.",
    },
  ];

  const servicesSliderRef = useRef(null);
  useScrollHorizontalWheel(servicesSliderRef);

  return (
    <div className="our-services" id="services">
      <div className="content-info">
        <img
          src={image.seccion4}
          alt="End2End"
          className="end2-image"
        />
        <div className="end2-section-title">
          <motion.p
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
            viewport={{ once: true }}
            className="title-primary"
          >NUESTROS <strong>SERVICIOS</strong></motion.p>
        </div>

        <div className="carousel-container" ref={servicesSliderRef}>
          <Swiper
            spaceBetween={50}
            slidesPerView={2.5}
            grabCursor={true}
            pagination={{ clickable: false }}
            className="mySwiper"
            breakpoints={
              {
                0: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 1.5,
                  spaceBetween: 50,
                },
                1200: {
                  slidesPerView: 2.5,
                },
              }
            }
          >
            {cardsData.map((card, index) => (
              <SwiperSlide key={index}>
                <div className="card">
                  <div className="card-content">
                    <div className="card-content__header">
                      <h2 className="card-title">{card.title}</h2>
                      <img
                        src={card.image}
                        alt={card.title}
                        className="card-image"
                      />
                    </div>
                    <div className="card-content__body">
                      <p className="card-description">{card.description}</p>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};
