import React, { useEffect, useRef } from "react";

export const HubspotContactForm = () => {
  const formRef = useRef(null);
  const formId = process.env.REACT_APP_HUBSPOT_FORM_ID;
  const portalId = process.env.REACT_APP_HUBSPOT_PORTAL_ID;



  useEffect(() => {
    console.log('portalId', portalId);
    console.log('formId', formId);

    const script = document.createElement('script');
    script.src = 'https://js.hsforms.net/forms/embed/v2.js';

    script.addEventListener('load', () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          target: `#${formRef.current.id}`,
          region: "na1",
          portalId: portalId,
          formId: formId,
        })
      }
    });

    document.body.appendChild(script);


    return () => {
      document.body.removeChild(script);
    };

  }, [formId, portalId]);

  return (
    <div id="hubspotForm" ref={formRef}></div>
  );
}

export default HubspotContactForm;