import { useState, useEffect } from 'react';

export function useScrollHorizontalWheel(containerRef) {
  const [translateX, setTranslateX] = useState(0);
  const [maxScroll, setMaxScroll] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      const swiperWrapper = containerRef.current.querySelector('.swiper-wrapper');
      if (swiperWrapper) {
        const sliderWidth = swiperWrapper.scrollWidth;
        const containerWidth = swiperWrapper.clientWidth;
        setMaxScroll(sliderWidth - containerWidth);
      }
    }
  }, [containerRef]);

  useEffect(() => {
    const handleScroll = (event) => {
      if (!containerRef.current) return;

      const swiperWrapper = containerRef.current.querySelector('.swiper-wrapper');
      if (!swiperWrapper) return;

      const { top, bottom, height } = containerRef.current.getBoundingClientRect();
      const withinBounds = top < (window.innerHeight / 2) && bottom > 0;

      if (withinBounds) {
        const delta = event.deltaY;
        let newTranslateX = translateX + delta;
        let atEdge = false;

        if (newTranslateX <= 0) {
          newTranslateX = 0;
          atEdge = true;
        } else if (newTranslateX >= maxScroll) {
          newTranslateX = maxScroll;
          atEdge = true;
        }

        if (!atEdge) {
          event.preventDefault();
          if (top < 0 || bottom > window.innerHeight) {
            window.scrollTo({
              top: window.scrollY + top - (window.innerHeight / 2 - height / 2),
              behavior: 'smooth'
            });
          }
        }

        swiperWrapper.style.transform = `translate3d(-${newTranslateX}px, 0, 0)`;
        swiperWrapper.style.transition = 'transform 0.3s ease-out';
        setTranslateX(newTranslateX);
      }
    };

    window.addEventListener('wheel', handleScroll, { passive: false });

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [translateX, maxScroll, containerRef]);

  return { translateX, setTranslateX };
}