import React, { useEffect, useState } from "react";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import { BsCart2 } from "react-icons/bs";
import image from "../assets";
import { HiOutlineBars3 } from "react-icons/hi2";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import Drawer from "@mui/material/Drawer";
import ListItemText from "@mui/material/ListItemText";
import "../App.scss";
import { Box } from "@mui/material";

export const Navbar = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [isCrolled, setIsCrolled] = useState(false);

  const menuOptions = [
    {
      text: "Sobre nosotros",
      link: "#aboutUs"
    },
    {
      text: "Modelo end2end",
      link: "#end2End"
    },
    {
      text: "Nuestros servicios",
      link: "#services"
    },
    {
      text: "Tipo de empresas",
      link: "#typeBusiness"
    },
    {
      text: "Casos de éxito",
      link: "#success"
    },
    {
      text: "Clientes",
      link: "#clients"
    },
    {
      text: "Contáctenos",
      link: "#contactUs"
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const contactUsSection = document.querySelector('#contactUs');
      const contactFooter = document.querySelector('.footer-section');
      const position = contactUsSection.getBoundingClientRect().top + + window.scrollY - contactUsSection.offsetHeight / 2;
      const positionFooter = contactFooter.getBoundingClientRect().top + + window.scrollY - contactUsSection.offsetHeight / 2;

      if (window.scrollY >= position && window.scrollY < positionFooter) {
        setIsCrolled(true);
      } else {
        setIsCrolled(false);
      }

    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }

  }, [])


  return (
    <nav className={`${isCrolled ? 'section-contact' : ''}`}>
      <div className={`navbar-links-container`}>
        <img
          src={image.travel_menu}
          alt="logo"
          className="navbar-logo"
        />
        {
          menuOptions.map((item) => (
            <a key={`menu-option-${item.link}`} href={item.link}>{item.text}</a>
          ))
        }
      </div>
      <div className="navbar-menu-container">
        <HiOutlineBars3 onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        anchor="left"
      >
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <div className="logo-menu-mobile">
            <img
              className="banner__logo"
              src={image.travel}
              alt="Logo"
            />

          </div>
          <List>
            {menuOptions.map((item) => (
              <ListItem
                key={item.text}
                disablePadding
              >
                <ListItemButton>
                  <a href={item.link} className="link-mobile">
                    <ListItemText primary={item.text} />
                  </a>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
    </nav>
  );
};
