import React, { useRef } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import { motion } from "framer-motion"

import image from "../assets";

export const CompanyTypes = () => {
  const itemData = [
    {
      img: image.seccion5_img1,
      title: "MARCAS DESTINO",
    },
    {
      img: image.seccion5_img2,
      title: "AEROLÍNEAS",
    },
    {
      img: image.seccion5_img3,
      title: "RESTAURANTES Y TIENDAS DE SOUVENIRS​",
    },
    {
      img: image.benefits_agencia,
      title: "AGENCIAS DE VIAJES Y TOURS.",
    },
    {
      img: image.benefits_renta,
      title: "RENTA DE CARROS Y EMPRESAS DE TRANSPORTE",
    },
    {
      img: image.benefits_hotels,
      title: "HOTELES & RESORTS",
    },
  ];

  const companySectionRef = useRef(null)
  const companySectionTitleRef = useRef(null)

  return (
    <div className="benefits" id="typeBusiness" ref={companySectionRef}>
      <div
        className="benefits__title title-primary"
        ref={companySectionTitleRef}
      >
        <motion.p
          initial={{ y: 150, opacity: 1 }}
          whileInView={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.7 }}
          viewport={{ once: true }}
        >¿QUÉ TIPO DE EMPRESAS PUEDEN <strong>BENEFICIARSE DE NUESTROS SERVICIOS?</strong></motion.p>
      </div>

      <div className="benefits__mansory">
        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 2, 768: 2, 900: 3 }}
        >
          <Masonry gutter="15px">
            {itemData.map((item) => (
              <motion.div
                initial={{ y: 150, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.6 }}
                viewport={{ once: true }}
                className="benefits__card"
                key={item.title}
              >
                <img
                  className="benefits__card-image"
                  src={item.img}
                  alt={item.title}
                  loading="lazy"
                />
                <p className="benefits__card-text">{item.title}</p>
              </motion.div>
            ))}
          </Masonry>

        </ResponsiveMasonry>
      </div>
    </div>
  );
};
